import React from "react"
import { SEO } from "../components/seo"

import Layout from "../components/layout"

const Index = () => {
  return <Layout />
}

export default Index

export const Head = () => <SEO />
