import React from "react"

import SpotifySVG from "./spotify-svg"
import AppleMusicSVG from "./apple-music-svg"

import * as buttonStyles from "../styles/playOnPlatfromButtonStyles.module.scss"

const PlayOnPlatformButton = ({ platform, type }) => {
  let platformLink = ""

  // Still Loving Your Eyes
  // if (platform === "spotify") {
  //   platformLink =
  //     "https://open.spotify.com/track/0xQ69QnTZraiE93OZrLIfq?si=d452723a6b1345c9"
  // } else if (platform === "apple") {
  //   platformLink =
  //     "https://music.apple.com/cz/album/still-loving-your-eyes/1509519743?i=1509519744&l=cs"
  // }

  //Reasons Why We Cry
  if (platform === "spotify") {
    platformLink =
      "https://open.spotify.com/album/2ZfaDHhphNaixAdLYnZpTY?si=VdppxnOFR0i97LJemVxSsw"
  } else if (platform === "apple") {
    platformLink =
      "https://music.apple.com/us/album/reasons-why-we-cry/1691338609"
  }

  return (
    <div className={buttonStyles.playOnPlatform}>
      <a href={platformLink} target="_blank" rel="noreferrer">
        <div className={buttonStyles.container}>
          <div className={buttonStyles.text}>
            {type === "listen" && <p>Listen on</p>}
            {type === "presave" && <p>Pre-save on</p>}
            {type === "preorder" && <p>Pre-order on</p>}
          </div>
          <div className={buttonStyles.icon}>
            {platform === "spotify" && <SpotifySVG />}
            {platform === "apple" && <AppleMusicSVG />}
          </div>
        </div>
      </a>
    </div>
  )
}

export default PlayOnPlatformButton
