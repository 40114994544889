import React from "react"

import * as ytvideoStyles from "../styles/yt-video.module.scss"

const YTvideo = ({ videoSrcURL, videoTitle, ...props }) => {
  return (
    <div className={ytvideoStyles.ytVideo}>
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  )
}

export default YTvideo
