import React from "react"

import * as headerStyles from "../styles/header.module.scss"

const Header = () => {
  return (
    <header className={headerStyles.siteHeader}>
      <div className={headerStyles.container}>
        <p>Rudy Stalk</p>
      </div>
    </header>
  )
}

export default Header
