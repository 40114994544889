import React from "react"

import * as footerStyles from "../styles/footer.module.scss"

const Footer = () => {
  return (
    <footer className={footerStyles.siteFooter}>
      <div className={footerStyles.container}>
        <p>Rudy Stalk &copy; {new Date().getFullYear().toString()} </p>
      </div>
    </footer>
  )
}

export default Footer
