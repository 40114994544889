import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import PlayOnPlatformButton from "./play-on-platform-button"
import BckgImage from "./bg-image"

import * as artStyles from "../styles/art.module.scss"

const Art = () => {
  let spotifyArtistLink =
    "https://open.spotify.com/artist/1I3sVE0Ht08StfbJRw4Yeo?si=wW3C3KpEROWM7ctvAhf7kQ"

  return (
    // <BckgImage section="art">
    //   <div className={artStyles.container}>
    //     <div className={artStyles.songCard}>
    //       <div className={artStyles.cover}>
    //         <StaticImage
    //           src="../images/cover-still-loving-your-eyes.jpeg"
    //           alt="Song cover"
    //           layout="fullWidth"
    //         />
    //       </div>
    //       <div className={artStyles.songInfo}>
    //         <p className={artStyles.songTitle}>Still Loving Your Eyes</p>
    //         <PlayOnPlatformButton platform={"spotify"} />
    //         <PlayOnPlatformButton platform={"apple"} />
    //       </div>
    //     </div>
    //     <div className={artStyles.moreMusic}>
    //       <a href={spotifyLink} target="_blank" rel="noreferrer">
    //         <p>more music</p>
    //       </a>
    //     </div>
    //   </div>
    // </BckgImage>

    <BckgImage section="art">
      <div className={artStyles.container}>
        <div className={artStyles.songCard}>
          <div className={artStyles.cover}>
            <StaticImage
              src="../images/ruda_reasons_why_we_cry_album_cover_3000x3000.jpg"
              alt="Song cover"
              layout="fullWidth"
            />
          </div>
          <div className={artStyles.songInfo}>
            <p className={artStyles.songTitle}>Reasons Why We Cry</p>
            <PlayOnPlatformButton platform={"spotify"} type={"listen"} />
            <PlayOnPlatformButton platform={"apple"} type={"listen"} />
          </div>
        </div>
        <div className={artStyles.moreMusic}>
          <a href={spotifyArtistLink} target="_blank" rel="noreferrer">
            <p>more music</p>
          </a>
        </div>
      </div>
    </BckgImage>
  )
}

export default Art
