import React from "react"

import * as countdownStyles from "../styles/countdown.module.scss"

const Countdown = () => {
  //   var countDownDate = new Date("June 28, 2023 11:00").getTime()
  //   var x = setInterval(function () {
  //     var now = new Date().getTime()
  //     var distance = countDownDate - now
  //     var days = Math.floor(distance / (1000 * 60 * 60 * 24))
  //     var hours = Math.floor(
  //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     )
  //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  //     var seconds = Math.floor((distance % (1000 * 60)) / 1000)

  //     document.getElementById("days").innerHTML = days
  //     document.getElementById("hours").innerHTML = hours
  //     document.getElementById("minutes").innerHTML = minutes
  //     document.getElementById("seconds").innerHTML = seconds

  //     if (distance < 0) {
  //       clearInterval(x)
  //       document.getElementById("header").innerHTML = "New Album Out NOW"
  //     }
  //   }, 1000)

  return (
    <div section="countdown">
      <div className={countdownStyles.container}>
        <div id="header" className={countdownStyles.header}>
          new album out now
        </div>
        {/* <div className={countdownStyles.timer}>
          <div>
            <p id="days"></p>
            <p className={countdownStyles.units}>days</p>
          </div>
          <div>
            <p id="hours"></p>
            <p className={countdownStyles.units}>hours</p>
          </div>
          <div>
            <p id="minutes"></p>
            <p className={countdownStyles.units}>minutes</p>
          </div>
          <div>
            <p id="seconds"></p>
            <p className={countdownStyles.units}>seconds</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Countdown
